import React from 'react'
import PropTypes from 'prop-types'
import {  graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/hero'
import bulmaCarousel from "bulma-carousel";
import PageButtons from '../components/page-buttons'

export default class IndexPage extends React.Component {

  componentDidMount() {
    bulmaCarousel.attach();
  }

  render() {
    const { data,pageContext } = this.props
    const { frontmatter: content } = data.markdownRemark
    console.log(pageContext)
    return (
      <Layout  isHomePage>
        <div>
          <div id="carousel-wrap">
            <Hero
              images={content.images}
              isLarge
            />
          </div>
        </div>
        <div style={{paddingTop:20}} className='row'>
          <div className="section">
          <PageButtons links={content.links} />
          </div>
        </div>

      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      id: PropTypes.string,
      frontmatter: PropTypes.object
    }),
  }),
}

IndexPage.defaultProps={

}

export const pageQuery = graphql`
  query IndexQuery {
    markdownRemark(frontmatter:{templateKey:{eq:"home-page"}}){
    id
    frontmatter{
      images{
        src
        alt
      }
      links{
        name
        url
      }
    }
  }
  }
`
