import React from "react";
import Image from './image'
import Nav from './nav'
import PropTypes from "prop-types";


const Component = ({images,showNav}) => {
  return (
    <div className="hero-carousel  carousel-animated carousel-animate-fade" data-delay="5000"  data-autoplay="true">
      <div className='carousel-container'>
        {images.map((image,index)=>(
          <Image key={index}
          src={image.src || ""}
          alt={image.alt || ""}
        />))
        }
      </div>
      {showNav && <Nav/>}
    </div>
  );
};

Component.propTypes = {
  images:PropTypes.array,
  showNav:PropTypes.bool
};
Component.defaultProps = {
  images:[],
  showNav:true
};

export default Component;
