import React from "react";
import {Hero,HeroHeader,HeroBody,HeroFooter} from 'bloomer'
import PropTypes from "prop-types";

const Component = ({getTop,getBottom,getBody,classNames,style,children}) => {
  return (
    <Hero isBold isColor='primary' style={style} tag='div' className={classNames}>
        {children}
        <HeroHeader>
          {getTop && getTop()}
        </HeroHeader>
        <HeroBody className='has-text-centered'>
          {getBody && getBody()}
        </HeroBody>
        <HeroFooter>
          {getBottom && getBottom()}
        </HeroFooter>
    </Hero>
  );
};

Component.propTypes = {
  getTop:PropTypes.func,
  getBody:PropTypes.func,
  getBottom:PropTypes.func,
  classNames:PropTypes.any,
  style:PropTypes.any,
};



export default Component;
