import React from "react";
import Carousel from './carousel/index'
import Hero from './base'
import PropTypes from "prop-types";
import classNames from 'classnames'

const imagesAreMultiple=(images)=>Array.isArray(images) && images.length >1;

const getClassnames=(isLarge,hasCarousel)=>{
  return classNames(
    {'is-large':isLarge},
    {'is-normal':!isLarge},
    'is-paddingless',
    'is-bold',
    {'has-carousel':hasCarousel},
  )
}

const prepareStyle =(images)=>{
  console.log(images)
  return imagesAreMultiple(images)?{}:(images[0]&& (images[0].externalSrc || images[0].src))?
    {
      backgroundImage:`url(${images[0].externalSrc || images[0].src})`,
      backgroundRepeat: 'no-repeat'
    }:{}
}

const Component = ({className,images,getTop,getBottom,getBody,isLarge}) => {
  return (
    <div className={className}>
      <Hero
        style={prepareStyle(images)}
        getTop={getTop}
        getBottom={getBottom}
        getBody={getBody}
        classNames={getClassnames(isLarge,imagesAreMultiple(images))}
      >{

        (imagesAreMultiple(images)) ?
          <Carousel
            images={images}
            showNav={Array.isArray(images) && images.length > 1}
          /> : null
      }
      </Hero>
    </div>
  );
};


Component.propTypes = {
  images:PropTypes.array,
  getTop:PropTypes.func,
  getBody:PropTypes.func,
  getBottom:PropTypes.func,
  isLarge: PropTypes.bool,
  className:PropTypes.string,
};
Component.defaultProps = {
};

export default Component;
