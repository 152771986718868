import React from "react";
import PropTypes from "prop-types";

const Component = ({src,alt}) => {
  return (
    <div className='carousel-item has-background is-active'>
      <img className="is-background" src={src} alt={alt} width={400} />
    </div>
  );
};

Component.propTypes = {
  src:PropTypes.string,
  alt:PropTypes.string
};
Component.defaultProps = {};

export default Component;
