import React from "react";
// import PropTypes from "prop-types";

const Component = (props) => {
  return (
    <div className="carousel-navigation is-overlay">
      <div className="carousel-nav-left">
        <i className="ion ion-chevron-left " aria-hidden="true"></i>
      </div>
      <div className="carousel-nav-right">
        <i className="ion ion-chevron-right" aria-hidden="true"></i>
      </div>
    </div>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
