import React from "react";
import {Column,Columns,Subtitle} from 'bloomer'
import {Link} from 'gatsby'
import PropTypes from "prop-types";

const Component = ({links}) => {
  return (
    <Columns  isMultiline>
      {Array.isArray(links) && links.map((link,key)=>(
        <Column key={key} className='is-4-widescreen is-6-tablet is-12-mobile'>
          <Columns>
            <Column>
              <div id='fancy-button'>
                <Link  to={link.url || '#'}>
                  <Subtitle className='is-uppercase'>
                    {link.name}
                  </Subtitle>
                </Link>
              </div>
            </Column>
          </Columns>
        </Column>
      ))}

    </Columns>
  );
};

Component.propTypes = {
  links:PropTypes.array
};
Component.defaultProps = {};

export default Component;
